.text-shpere {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.tagcloud {
  display: inline-block;
}

.tagcloud--item {
  font-family: var(--font-family-title);
  color: var(--color-secondary);
}

.tagcloud--item:hover {
  color: var(--color-link);
}

.text-shpere ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 250px;
  margin: 40px auto;
}

.text-shpere ul li {
  padding: 5px;
  color: var(--color-secondary);
  text-shadow: 2px 2px 4px var(--color-secondary);;
}