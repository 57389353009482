* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  max-width: var(--max-width-content);
  margin: 0 auto;
  padding: 0 20px;
  font-family: var(--font-family-content);
  background: var(--color-bg);
  color: var(--color-text);
}

a {
  color: var(--color-link);
  text-decoration: none;
}

a:hover {
  color: var(--color-link-hover);
}

h1 {
  color: var(--color-title);
  font-family: var(--font-family-title);
  font-size: 55px;
}

h1 span {
  color: var(--color-secondary);
}

h2 {
  color: var(--color-title);
  font-family: var(--font-family-title);
  font-size: 48px;
}

img {
  width: 100%;
}

ul {
  list-style: none;
}

p {
  text-indent: 20px;
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-secondary);
  z-index: 2;
}