@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');

:root {
    --max-width-content: 1225px;

    --font-family-title: 'Poppins', sans-serif;
    --font-family-content: 'Roboto', sans-serif;

    --color-bg: linear-gradient(201.38deg, #5B5C56 -15.3%, #131311 26.88%);
    --color-bg-2: linear-gradient(136.48deg, #5A5B56 -29.08%, #131311 35.75%);
    --color-bg-3: linear-gradient(0.46deg, #595B56 -222.72%, #131311 79.16%);
    --color-bg-4: linear-gradient(320deg, #5A5B56 -30.33%, #131311 22.65%);
    --color-secondary: #F2F0C0;
    --color-title: #fff;
    --color-text: #82837D;
    --color-link: #fff;
    --color-link-hover: var(--color-secondary);
    --color-social-hover: #fff;
    --color-hover-border-btn: #64572E;
}