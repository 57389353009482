.portfolio_items-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: var(--color-bg-3);
  width: 100%;
  height: 100%;
  min-height: 383px;
  max-height: 383px;
  max-width: 345px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.portfolio_items-item-logo {
  width: 80%;
}

.portfolio_items-item-wrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  height: 90px;
  display: flex;
  justify-content: center;
  margin-top: -45px;
  transition: margin-top 0.5s ease-out;
}

.portfolio_items-item-shortdescr {
  padding: 15px;
  position: absolute;
  top: 80%;
  margin-top: -50px;
  opacity: 0;
  transition: top 1s ease-out, opacity 0.5s ease-out;
}

.portfolio_items-item:hover .portfolio_items-item-wrapper {
  margin-top: -100px;
}

.portfolio_items-item:hover .portfolio_items-item-shortdescr {
  top: 60%;
  opacity: 1;
}

.portfolio_items-item-shortdescr p {
  text-indent: 0;
}

.portfolio_items-item:hover svg {
  opacity: 1;
}

.portfolio_items-item [data-label="triona"] img {
  width: 60%;
  padding-bottom: 15px;
}

.portfolio_items-item [data-label="marinenanoshop"] {
  height: 190px;
  margin-top: -100px;
}

.portfolio_items-item:hover .portfolio_items-item-wrapper[data-label="marinenanoshop"] {
  margin-top: -190px;
}

.portfolio_items-item:hover .portfolio_items-item-shortdescr[data-label="marinenanoshop"] {
  top: 70%;
}

.portfolio_items-item .portfolio_items-item-wrapper[data-label="dev-raiks-portfolio"] img {
  width: 36px;
}

@media screen and (max-width: 768px) {
  .portfolio_items-item:hover .portfolio_items-item-wrapper {
    margin-top: -135px;
  }
  
  .portfolio_items-item:hover .portfolio_items-item-shortdescr {
    top: 50%;
  }
}
