svg path {
  transition: 1s all ease;
}

svg:hover path {
  stroke-width: 1px;
}

#header_line-one {
  width: 144px;
  height: 406px;
  position: absolute;
  top: -80px;
  left: 0;
}

#header_line-two {
  width: 17px;
  height: 409px;
  position: absolute;
  bottom: 35px;
  left: 80px;
  transition: 2s all ease;
}

#header_line-two:hover {
  opacity: 0.5;
}

#about-line-one {
  position: absolute;
  top: -2px;
  left: -2px;
}

svg#about-line-one:hover path {
  stroke-width: 2px;
}

#about-line-two {
  position: absolute;
  width: 127px;
  height: 155px;
  bottom: -155px;
  left: 115px;
  z-index: 1;
  transition: 2s all ease;
}

#about-line-two:hover {
  opacity: 0.5;
}

.btn-radius-one {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 20px;
  height: 20px;
}

.btn-radius-two {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 20px;
  height: 20px;
}

.btn-bottom-line {
  position: absolute;
  bottom: -1px;
  left: 50%;
  margin-left: -33px;
  width: 100%;
  max-width: 65px;
  height: 1px;
}

.portfolio-line {
  position: absolute;
  width: 100%;
  max-width: 1189px;
  height: 116px;
  bottom: 0;
  right: -2px;
  transition: 2s all ease;
  z-index: 0;
}

.portfolio-line {
  opacity: 0.5;
}

.zoom-icon {
  position: absolute;
  opacity: 0;
  top: 15px;
  right: 15px;
  width: 45px;
  height: 45px;
  z-index: 1;
  cursor: pointer;
  transition: opacity 0.5s ease-out;
}

.zoom-icon:hover path {
  fill: var(--color-link);
}

@media screen and (max-width: 768px) {
  #header_line-two {
    left: 30px;
  }

  #about-line-one {
    top: -1px;
    left: -1px;
  }
}