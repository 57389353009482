.mobile-nav {
  z-index: 3;
  display: none;
  justify-content: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 230px;
  background: var(--color-bg);;
}

.btn-icon {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

.btn-icon svg path {
  stroke: var(--color-link);
}

.mobile_links {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

.mobile_link {
  list-style: none;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex: 20px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: auto;
  flex: 1;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .mobile-nav {
    display: flex;
    position: fixed;
    width: 100%;
    height: 85px;
  }

  .mobile-nav .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .btn-icon,
  .mobile_links,
  .background {
    position: fixed;
    z-index: 2;
  }
}