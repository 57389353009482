.nav {
  position: relative;
  z-index: 1;
  padding: 21px 20px;
}

.nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.nav_links-link a {
  margin-right: 50px;
}

.nav_links-link a:last-child {
  margin-right: 0;
}

.nav_links-link a:hover {
  color: var(--color-link-hover);
}

.nav_socials-social {
  display: flex;
}

.nav_socials-social a {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.nav_socials-social a:hover path {
  fill: var(--color-social-hover);
}

.nav_socials-social a:last-child {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .nav {
    display: none;
  }
}