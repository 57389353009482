.about {
  display: flex;
  align-items: center;
  position: relative;
  background: var(--color-bg-2);
  border-radius: 20px 0;
  padding: 55px 25px 25px;
  margin-bottom: 140px;
}

.about_info-title {
  font-family: var(--font-family-title);
  font-size: 48px;
  color: var(--color-title);
  margin-bottom: 30px;
}

.about_info-content {
  font-size: 18px;
  line-height: 150%;
  text-indent: 20px;
}

@media screen and (max-width: 768px) {
  .about {
    flex-direction: column;
    border-radius: 2px 0;
    padding: 10px 10px 10px;
  }

  .about_info-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .about_info-content {
    font-size: 16px;
    line-height: 130%;
  }
}