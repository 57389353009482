.main-title {
  color: var(--color-text);
  font-size: 170px;
  opacity: 17%;
  line-height: 200px;
}

.project-images {
  position: relative;
  margin-top: -70px;
}

.project_content {
  display: flex;
  padding: 50px 20px 30px;
}

.project_content-descr {
  max-width: 985px;
  width: 87%;
  font-size: 18px;
  line-height: 148%;
}

.project_content-skills {
  color: var(--color-secondary);
  width: 14%;
  text-align: end;
}

.project_content-skills li {
  margin-bottom: 5px;
}

.project_nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 20px 10px;
}

.project_nav a {
  margin-bottom: 10px;
}

.project_visit {
  margin-right: 10px;
}

@media screen and (max-width: 1240px) {
  .single-project {
    padding-top: 20px;
  }

  .main-title {
    font-size: calc(1rem + 6.23vw);
    line-height: 38px;
    height: 212px;
    padding-top: 70px;
  }
}

@media screen and (max-width: 768px) {
  .project_content {
    flex-direction: column;
  }

  .project_content-descr {
    width: 100%;
    margin-bottom: 20px;
  }

  .project_content-skills {
    width: 100%;
  }

  .project_content-skills ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 200px;
    margin: 0 auto;
  }

  .project_content-skills ul li {
    margin: 0 10px 10px;
  }

  .project_nav {
    padding: 20px 20px 10px;
  }
}