.btn {
  position: relative;
  width: max-content;
  font-size: 16px;
  color: var(--color-link);
  line-height: 150%;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: border 1s ease-out;
}

.btn:hover {
  border: 1px solid var(--color-hover-border-btn);
}

.btn svg {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.btn:hover svg,
.btn-active svg {
  opacity: 0;
}

.btn-active {
  border: 1px solid var(--color-hover-border-btn);
}