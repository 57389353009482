.portfolio {
  position: relative;
  padding: 0 0 70px 0;
  border-radius: 20px 0;
  background: var(--color-bg-4);
  margin-bottom: 50px;
}

.portfolio_title {
  padding: 25px 0;
}

.portfolio_filter {
  margin: 25px 0 5px 0;
}

.portfolio_filter a {
  margin: 0 20px 20px 0;
}

.portfolio_filter a:first-child {
  margin-left: 0;
}

.portfolio_items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding-top: 26px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .portfolio {
    padding: 0 15px 70px 15px;
    border-radius: 8px 0;
  }

  .portfolio_title {
    font-size: 35px;
  }

  .portfolio_filter {
    display: flex;
    flex-wrap: wrap;
  }

  .portfolio_items {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}