.icon-mail {
  position: absolute;
  top: 4px;
}

@media screen and (max-width: 768px) {
  .icon-mail {
    position: static;
  }

  .icon-linkedin svg,
  .icon-mail svg {
    width: 20%;
    height: 100%;
  }

  .mobile-link {
    font-size: 25px;
  }
}