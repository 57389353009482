.error-main {
  position: relative;
  height: 100vh;
}

.error_page {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.error-main footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}