.footer {
  display: flex;
  justify-content: space-between;
  padding: 50px 20px 40px;
}

.footer_copyright {
  color: var(--color-title);
}

.footer_links a {
  padding-right: 55px;
}

.footer_links a:last-child {
  padding-right: 0;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer_links {
    margin-bottom: 20px;
  }
}