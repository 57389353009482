.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 120px 0 70px 0;
}

.header_info {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 560px;
  min-height: 496px;
  margin-left: 25px;
}

.header_info h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  z-index: -1;
  line-height: 100px;
}

.header_info svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header_info svg rect {
  transition: 1s all ease;
}

.header_info svg:hover rect {
  stroke-width: 2px;
}

.header_info_job {
  color: var(--color-title);
  font-size: 48px;
}

.header_image {
  margin-top: 12px;
}

@media screen and (max-width: 1050px) {
  .header_info h1 {
    font-size: 30px;
    text-align: center;
    line-height: 55px;
  }

  .header_info_job {
    font-size: 25px;
  }
}

@media screen and (max-width: 768px) {
  .header_info h1 {
    font-size: 25px;
    transform: translate(-50%, 100%);
  }

  .header_info_job {
    font-size: 20px;
  }

  .header_image {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -120px;
    margin-top: 100px;
    max-width: 240px;
    z-index: -2;
  }

  .header_image img {
    max-width: 240px;
  }
}